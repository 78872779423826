<!--
 * @Author: zangqisong
 * @Date: 2023-12-12 23:12:39
 * @LastEditors: zangqisong
 * @LastEditTime: 2024-03-18 21:27:07
 * @Description:
-->
<template>
  <div
    :class="`nav ${
      isShowNav ? 'translate-x-0' : 'translate-x-[-100vw]'
    } transition-transform`"
  >
    <template v-for="item in navList" :key="item">
      <router-link :to="item.path">
        <div class="nav-item flex-b-c" @click="isShowNav = false">
          {{ item.name }}
          <van-icon name="arrow" />
        </div>
      </router-link>
    </template>
  </div>
</template>

<script setup>
import { inject, reactive } from "vue";

const isShowNav = inject("isShowNav");

/** 导航列表 */
const navList = reactive([
  {
    name: "Home",
    path: "/",
  },
  // {
  //   name: "Stores",
  //   path: "/stores"
  // },
  {
    name: "Games",
    path: "/games",
  },
  {
    name: "Events",
    path: "/tournaments",
  },
  {
    name: "News",
    path: "/news",
  },
  // {
  //   name: "Shop",
  //   path: "/shop"
  // },
  // {
  //   name: "Membership",
  //   path: "/membership",
  // },
  {
    name: "Points System",
    path: "/integral",
  },
  {
    name: "How it works",
    path: "/faqList",
  },
  {
    name: "About Us",
    path: "/about",
  },
  // {
  //   name: "Sidekick",
  //   path: "/sidekick"
  // }
  // {
  //   name: "Top Up",
  //   path: "/top-up",
  // },
  // {
  //   name: "Integral",
  //   path: "/integral"
  // }
]);

/** 是否有padding*/
// const isPadding = inject("isPadding");
// isPadding.value = false;

/** 跳转到指定页面 */
const linkToView = (url) => {
  window.location.href = url;
};
</script>

<style lang="scss" scoped>
@use "@/styles/base.scss" as *;

.nav {
  width: 40dvw;
  height: 100dvh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: black;
  display: flex;
  flex-direction: column;
  margin-top: 90px;
  z-index: 100;

  @include media("<tablet") {
    width: 100dvw;
  }

  .nav-item {
    position: relative;
    padding: 20px 30px;
    font-size: 18px;
    color: white;
    border-bottom: 1px solid #3e4a5a;

    @include font-bold;

    &.flex-b-c {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }
  }
}
</style>
